define("@ember/test/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.registerHelper = _exports.registerAsyncHelper = _exports._impl = void 0;
  _exports.registerTestImplementation = registerTestImplementation;
  _exports.unregisterWaiter = _exports.unregisterHelper = _exports.registerWaiter = void 0;
  let registerAsyncHelper = _exports.registerAsyncHelper = void 0;
  let registerHelper = _exports.registerHelper = void 0;
  let registerWaiter = _exports.registerWaiter = void 0;
  let unregisterHelper = _exports.unregisterHelper = void 0;
  let unregisterWaiter = _exports.unregisterWaiter = void 0;
  let _impl = _exports._impl = void 0;
  let testingNotAvailableMessage = () => {
    throw new Error('Attempted to use test utilities, but `ember-testing` was not included');
  };
  _exports.registerAsyncHelper = registerAsyncHelper = testingNotAvailableMessage;
  _exports.registerHelper = registerHelper = testingNotAvailableMessage;
  _exports.registerWaiter = registerWaiter = testingNotAvailableMessage;
  _exports.unregisterHelper = unregisterHelper = testingNotAvailableMessage;
  _exports.unregisterWaiter = unregisterWaiter = testingNotAvailableMessage;
  function registerTestImplementation(impl) {
    let {
      Test
    } = impl;
    _exports.registerAsyncHelper = registerAsyncHelper = Test.registerAsyncHelper;
    _exports.registerHelper = registerHelper = Test.registerHelper;
    _exports.registerWaiter = registerWaiter = Test.registerWaiter;
    _exports.unregisterHelper = unregisterHelper = Test.unregisterHelper;
    _exports.unregisterWaiter = unregisterWaiter = Test.unregisterWaiter;
    _exports._impl = _impl = impl;
  }
});
