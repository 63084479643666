define("ember-svg-jar/inlined/ballon-talk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M27.833 12.112a5.36 5.36 0 00-2.544-1.805l-2.603-.845a1.028 1.028 0 010-1.937l2.602-.845a5.362 5.362 0 003.323-3.33l.022-.064.846-2.6a1.027 1.027 0 011.938 0l.846 2.6A5.356 5.356 0 0035.66 6.68l2.602.845.052.013a1.028 1.028 0 010 1.937l-2.602.845a5.361 5.361 0 00-3.397 3.394l-.846 2.6a1.027 1.027 0 01-1.563.497 1.027 1.027 0 01-.375-.497l-.846-2.6a5.354 5.354 0 00-.852-1.602zm14.776 6.872l-1.378-.448a2.839 2.839 0 01-1.797-1.796l-.448-1.377a.544.544 0 00-1.027 0l-.448 1.377a2.835 2.835 0 01-1.77 1.796l-1.378.448a.545.545 0 000 1.025l1.378.448c.151.05.298.113.438.188l.003.015a2.837 2.837 0 011.357 1.61l.448 1.377a.545.545 0 001.01.039v-.01a.504.504 0 00.016-.039l.448-1.377a2.837 2.837 0 011.798-1.796l1.378-.448a.545.545 0 000-1.025l-.028-.007zM20 3c2.094 0 4.113.322 6.01.919a3.38 3.38 0 01-1.37.902l-2.504.808c-.7-.085-1.413-.129-2.136-.129-9.665 0-17.5 7.835-17.5 17.5 0 3.188.851 6.173 2.338 8.745.17.293.213.642.12.967l-2.116 7.443 7.44-2.116a1.25 1.25 0 01.968.12A17.414 17.414 0 0020 40.5c8.74 0 15.985-6.408 17.29-14.782a2.55 2.55 0 002.541-.106C38.551 35.423 30.161 43 20 43c-3.45 0-6.698-.874-9.534-2.414l-8.235 2.342c-1.319.375-2.537-.844-2.162-2.162l2.342-8.238A19.917 19.917 0 010 23C0 11.954 8.954 3 20 3z\" fill=\"#242424\"/>",
    "attrs": {
      "width": "43",
      "height": "43",
      "viewBox": "0 0 43 43",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});