define("ember-a11y-refocus/components/navigation-narrator", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/destroyable", "@ember/service", "@ember/runloop", "@glimmer/tracking", "ember-a11y-refocus", "@ember/template-factory"], function (_exports, _component, _component2, _object, _destroyable, _service, _runloop, _tracking, _emberA11yRefocus, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    tabindex="-1"
    class="ember-sr-only ember-sr-only-focusable"
    id="ember-a11y-refocus-nav-message"
  >
    {{this.navigationText}}
  </div>
  {{#if this.skipLink}}
    <a
      href={{this.skipTo}}
      class="ember-a11y-refocus-skip-link {{if this.isSkipLinkFocused 'active'}}"
      {{on 'focus' this.handleSkipLinkFocus}}
      {{on 'blur' this.handleSkipLinkFocus}}
      id="ember-a11y-refocus-skip-link"
    >
      {{this.skipText}}
    </a>
  {{/if}}
  */
  {
    "id": "w0abpA/q",
    "block": "[[[10,0],[14,\"tabindex\",\"-1\"],[14,0,\"ember-sr-only ember-sr-only-focusable\"],[14,1,\"ember-a11y-refocus-nav-message\"],[12],[1,\"\\n  \"],[1,[30,0,[\"navigationText\"]]],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[30,0,[\"skipLink\"]],[[[1,\"  \"],[11,3],[16,6,[30,0,[\"skipTo\"]]],[16,0,[29,[\"ember-a11y-refocus-skip-link \",[52,[30,0,[\"isSkipLinkFocused\"]],\"active\"]]]],[24,1,\"ember-a11y-refocus-skip-link\"],[4,[38,3],[\"focus\",[30,0,[\"handleSkipLinkFocus\"]]],null],[4,[38,3],[\"blur\",[30,0,[\"handleSkipLinkFocus\"]]],null],[12],[1,\"\\n    \"],[1,[30,0,[\"skipText\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"div\",\"if\",\"a\",\"on\"]]",
    "moduleName": "ember-a11y-refocus/components/navigation-narrator.hbs",
    "isStrictMode": false
  });
  let NavigationNarratorComponent = _exports.default = (_class = class NavigationNarratorComponent extends _component2.default {
    get skipLink() {
      var _this$args$skipLink;
      return (_this$args$skipLink = this.args.skipLink) !== null && _this$args$skipLink !== void 0 ? _this$args$skipLink : true;
    }
    get skipTo() {
      var _this$args$skipTo;
      return (_this$args$skipTo = this.args.skipTo) !== null && _this$args$skipTo !== void 0 ? _this$args$skipTo : '#main';
    }
    get skipText() {
      var _this$args$skipText;
      return (_this$args$skipText = this.args.skipText) !== null && _this$args$skipText !== void 0 ? _this$args$skipText : 'Skip to main content';
    }
    get navigationText() {
      var _this$args$navigation;
      return (_this$args$navigation = this.args.navigationText) !== null && _this$args$navigation !== void 0 ? _this$args$navigation : 'The page navigation is complete. You may now navigate the page content as you wish.';
    }
    get routeChangeValidator() {
      var _this$args$routeChang;
      return (_this$args$routeChang = this.args.routeChangeValidator) !== null && _this$args$routeChang !== void 0 ? _this$args$routeChang : _emberA11yRefocus.defaultValidator;
    }
    constructor() {
      super(...arguments);

      // focus on the navigation message after render
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "isSkipLinkFocused", _descriptor2, this);
      _defineProperty(this, "timer", null);
      this.router.on('routeDidChange', this.onRouteChange);
      (0, _destroyable.registerDestructor)(this, () => {
        (0, _runloop.cancel)(this.timer);
        this.timer = null;
        this.router.off('routeDidChange', this.onRouteChange);
      });
    }
    onRouteChange(transition) {
      let shouldFocus = this.routeChangeValidator(transition);
      if (!shouldFocus) {
        return;
      }
      this.timer = (0, _runloop.schedule)('afterRender', this, function () {
        this.timer = null;
        document.body.querySelector('#ember-a11y-refocus-nav-message').focus();
      });
    }
    handleSkipLinkFocus() {
      this.isSkipLinkFocused = !this.isSkipLinkFocused;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isSkipLinkFocused", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onRouteChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onRouteChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleSkipLinkFocus", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleSkipLinkFocus"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, NavigationNarratorComponent);
});