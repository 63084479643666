define("@glimmer/env", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DEBUG = _exports.CI = void 0;
  const DEBUG = _exports.DEBUG = false;
  const CI = _exports.CI = false;
});
