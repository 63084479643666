define("ember-svg-jar/inlined/light-bulb-alt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 2.001a7.25 7.25 0 017.25 7.25c0 2.096-.9 4.02-2.663 5.742a.751.751 0 00-.175.265l-.032.103-1.13 4.895a2.25 2.25 0 01-2.02 1.737l-.173.007h-2.114a2.25 2.25 0 01-2.147-1.577l-.045-.167-1.13-4.895a.75.75 0 00-.206-.368c-1.68-1.64-2.577-3.463-2.659-5.443l-.006-.3.004-.239A7.25 7.25 0 0112 2.002zM14.115 18.5H9.884l.329 1.42a.75.75 0 00.627.574l.103.007h2.114a.75.75 0 00.7-.482l.03-.1.328-1.419zM12 3.501a5.75 5.75 0 00-5.746 5.53l-.004.22.007.277c.076 1.564.8 3.02 2.206 4.392.264.258.46.576.571.926l.049.178.455 1.975h4.923l.458-1.976a2.25 2.25 0 01.493-.969l.127-.134c1.404-1.372 2.128-2.828 2.204-4.392l.007-.277-.004-.22A5.75 5.75 0 0012 3.5z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});