define("ember-link/components/link/component", ["exports", "@ember/debug", "@glimmer/component", "ember-link/helpers/link"], function (_exports, _debug, _component, _link) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "LinkArgs", {
    enumerable: true,
    get: function () {
      return _link.LinkHelperNamedParams;
    }
  });
  _exports.default = void 0;
  class LinkComponent extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      (false && !(false) && (0, _debug.deprecate)('`<Link>` component is deprecated. Use `(link)` helper instead.', false, {
        id: 'ember-link.link-component',
        until: '3.0.0',
        for: 'ember-link',
        since: {
          available: '2.1.0',
          enabled: '2.1.0'
        }
      }));
    }
  }
  _exports.default = LinkComponent;
});