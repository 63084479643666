define("ember-engines/-private/route-ext", ["@ember/routing/route", "@ember/application", "ember-engines/-private/deprecate-transition-methods"], function (_route, _application, _deprecateTransitionMethods) {
  "use strict";

  /*
    Creates an aliased form of a method that properly resolves external routes.
  */
  function externalAlias(methodName, deprecatedMethodName) {
    return function _externalAliasMethod(routeName) {
      (0, _deprecateTransitionMethods.deprecateTransitionMethods)('route', deprecatedMethodName);
      let externalRoute = (0, _application.getOwner)(this)._getExternalRoute(routeName);
      let router = this._router || this.router;
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }
      return router[methodName](externalRoute, ...args);
    };
  }
  _route.default.reopen({
    transitionToExternal: externalAlias('transitionTo', 'transitionToExternal'),
    replaceWithExternal: externalAlias('replaceWith', 'replaceWithExternal')
  });
});