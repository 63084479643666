define("ember-link/components/link/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{~yield
    (link
      route=@route
      models=@models
      model=@model
      query=@query
      fromURL=@fromURL
      preventDefault=@preventDefault
      onTransitionTo=@onTransitionTo
      onReplaceWith=@onReplaceWith
    )
  ~}}
  
  */
  {
    "id": "HgnyJNTc",
    "block": "[[[18,9,[[28,[37,1],null,[[\"route\",\"models\",\"model\",\"query\",\"fromURL\",\"preventDefault\",\"onTransitionTo\",\"onReplaceWith\"],[[30,1],[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8]]]]]]],[\"@route\",\"@models\",\"@model\",\"@query\",\"@fromURL\",\"@preventDefault\",\"@onTransitionTo\",\"@onReplaceWith\",\"&default\"],false,[\"yield\",\"link\"]]",
    "moduleName": "ember-link/components/link/template.hbs",
    "isStrictMode": false
  });
});